import SAMSUNGF14 from "assets/samsung/SAMSUNGF14.jpg";
//realme
import REALMEC63JADEGREEN1 from "assets/realme/REALMEC63/REALMEC63JADEGREEN/REALMEC63JADEGREEN1.jpeg";

import REALME11GLORYGOLD1 from "assets/realme/REALME11/REALME11GLORYGOLD/REALME11GLORYGOLD1.jpeg";

import REALME12TWILIGHTPURPLE1 from "assets/realme/REALME12/REALME12TWILIGHTPURPLE/REALME12TWILIGHTPURPLE1.jpeg";

import REALMEC67SUNNYOASIS1 from "assets/realme/REALMEC67/REALMEC67SUNNYOASIS/REALMEC67SUNNYOASIS1.jpeg";

import REALME12PLUSNAVIGATORBEIGE1 from "assets/realme/REALME12PLUS/REALME12PLUSNAVIGATORBEIGE/REALME12PLUSNAVIGATORBEIGE1.jpeg";

import REALME13PROPLUSMONETPURPLE1 from "assets/realme/REALME13PROPLUS/REALME13PROPLUSMONETPURPLE/REALME13PROPLUSMONETPURPLE1.jpeg";

import REALME13PROEMERALDGREEN1 from "assets/realme/REALME13PRO/REALME13PROEMERALDGREEN/REALME13PROEMERALDGREEN1.jpeg";

import REALME13PLUSVICTORYGOLD1 from "assets/realme/REALME13PLUS/REALME13PLUSVICTORYGOLD/REALME13PLUSVICTORYGOLD1.jpeg";

import REALMEC65FEATHERGREEN1 from "assets/realme/REALMEC65/REALMEC65FEATHERGREEN/REALMEC65FEATHERGREEN1.jpeg";

import REALME14PROJAIPURPINK1 from "assets/realme/REALME14PRO/REALME14PROJAIPURPINK/REALME14PROJAIPURPINK1.jpeg";

import REALME14PROPLUSBIKANERPRUPLE from "assets/realme/REALME14PROPLUS/REALME14PROPLUSBIKANERPRUPLE/REALME14PROPLUSBIKANERPRUPLE1.jpeg";

import REALME14XJEWELRED1 from "assets/realme/REALME14XJEWELRED/REALME14XJEWELRED1.jpeg";

//mi
import REDMI13CBLACK1 from "assets/mi/REDMI13C/StarlightBlack/redmi13cBlack1.jpg";

import REDMI13CGREEN1 from "assets/mi/REDMI13C/StartrailGreen/redmi13cGreen1.jpg";

import REDMI13CSILVER1 from "assets/mi/REDMI13C/StartrailSilver/redmi13cSilver1.jpg";

import REDMINOTE13WHITE1 from "assets/mi/REDMINOTE13/ArcticWhite/MINOTE13WHITE1.jpg";

import REDMINOTE13PURPLE1 from "assets/mi/REDMINOTE13/ChromaticPurple/MINOTE13PURPLE1.jpg";

import REDMINOTE13GOLD1 from "assets/mi/REDMINOTE13/PrismGold/MINOTE13GOLD1.jpg";

import REDMI135GBLACKDIAMOND1 from "assets/mi/REDMI135G/REDMI135GBLACKDIAMOND/REDMI135GBLACKDIAMOND1.jpg";

import REDMINOTE13PROPLUS5GFUSIONBLACK1 from "assets/mi/REDMINOTE13PROPLUS5G/REDMINOTE13PROPLUS5GFUSIONBLACK/REDMINOTE13PROPLUS5GFUSIONBLACK1.jpg";

import REDMINOTE14PHANTOMPURPLE1 from "assets/mi/REDMINOTE14/REDMINOTE14PHANTOMPURPLE/REDMINOTE14PHANTOMPURPLE1.jpeg";

import REDMINOTE14PROIVYGREEN1 from "assets/mi/REDMINOTE14PRO/REDMINOTE14PROIVYGREEN/REDMINOTE14PROIVYGREEN1.jpeg";

import REDMINOTE14PROPLUSSPECTREBLUE1 from "assets/mi/REDMINOTE14PROPLUS/REDMINOTE14PROPLUSSPECTREBLUE/REDMINOTE14PROSPECTREBLUE1.jpeg";

import REDMI14CSTARLIGHTBLUE1 from "assets/mi/REDMI14C/REDMI14CSTARLIGHTBLUE/REDMI14CSTARLIGHTBLUE1.jpeg";
//oppo import

import OPPOF29GLACIERBLUE1 from "assets/oppo/OPPOF29/OPPOF29GLACIERBLUE/OPPOF29GLACIERBLUE1.jpg";

import OPPOF29PROMARBLEWHITE1 from "assets/oppo/OPPOF29PRO/OPPOF29PROMARBLEWHITE/OPPOF29PROMARBLEWHITE1.jpg";

import OPPOA77ORANGE1 from "assets/oppo/OPPOA77/OPPOA77SUNSETORANGE/OPPOA77SUNSETORANGE1.jpg";

import OPPOK12XBLUE1 from "assets/oppo/OPPOK12X/OPPOK12XBREEZEBLUE/OPPOK12XBREEZEBLUE1.jpg";

import OPPOK12XVOILET1 from "assets/oppo/OPPOK12X/OPPOK12XMIDNIGHTVOILET/OPPOK12XMIDNIGHTVOILET1.jpg";

import OPPOA3XSPARKLEBLACK1 from "assets/oppo/OPPOA3X/OPPOA3XSPARKLEBLACK/OPPOA3XSPARKLEBLACK1.jpg";

import OPPOF27AMBERORANGE1 from "assets/oppo/OPPOF27/OPPOF27AMBERORANGE/OPPOF27AMBERORANGE1.jpg";

import OPPOF27EMERALDGREEN1 from "assets/oppo/OPPOF27/OPPOF27EMERALDGREEN/OPPOF27EMERALDGREEN1.jpg";

import OPPOA3PROMOONLIGHTPURPLE1 from "assets/oppo/OPPOA3PRO/OPPOA3PROMOONLIGHTPURPLE/OPPOA3PROMOONLIGHTPURPLE1.jpg";

import OPPOA3PROSTARRYBLACK1 from "assets/oppo/OPPOA3PRO/OPPOA3PROSTARRYBLACK/OPPOA3PROSTARRYBLACK1.jpg";

import RENO135GLUMINOUSWHITE1 from "assets/oppo/RENO13/RENO135GIVORYWHITE/RENO135GIVORYWHITE1.png";

import RENO135GIVORYWHITE1 from "assets/oppo/RENO13/RENO135GIVORYWHITE/RENO135GIVORYWHITE1.png";

import RENO13PROMISTLAVENDER1 from "assets/oppo/RENO13PRO/RENO13PROMISTLAVENDER/RENO13PROMISTLAVENDER1.jpeg";

import OPPOA3OCEANBLUE1 from "assets/oppo/OPPOA3/OPPOA3OCEANBLUE/OPPOA3OCEANBLUE1.jpeg";

//vivo

import VIVOY3005GPHANTOMPURPLE1 from "assets/vivo/VIVOY3005G/VIVOY3005GPHANTOMPURPLE/VIVOY3005GPHANTOMPURPLE1.jpg";

import VIVOY39GALAXYPURPLE1 from "assets/vivo/VIVOY39/VIVOY39GALAXYPURPLE/VIVOY39GALAXYPURPLE1.webp";

import VIVOY18GEMGREEN1 from "assets/vivo/VIVOY18/VIVOY18GEMGREEN/VIVOY18GEMGREEN1.jpeg";

import VIVOY18TSPACEBLACK from "assets/vivo/VIVOY18T/VIVOY18TSPACEBLACK/VIVOY18TSPACEBLACK1.jpeg";

import VIVOV40GANGESBLUE1 from "assets/vivo/VIVOV40/VIVOV40GANGESBLUE/VIVOV40GANGESBLUE1.jpg";

import VIVOV40LOTUSPURPLE1 from "assets/vivo/VIVOV40/VIVOV40LOTUSPURPLE/VIVOV40LOTUSPURPLE1.jpg";

import VIVOV40EMINTGREEN1 from "assets/vivo/VIVOV40E/VIVOV40EMINTGREEN/VIVOV40EMINTGREEN1.jpg";

import VIVOY18IPACEBLACK1 from "assets/vivo/VIVOY18I/PEACEBLACK/VIVOY18IPACEBLACK1.jpg";

import VIVOY28EBREEZEGREEN1 from "assets/vivo/VIVOY28E/VIVOY28EBREEZEGREEN/VIVOY28EBREEZEGREEN1.jpg";

import VIVOY28EVINTAGERED1 from "assets/vivo/VIVOY28E/VIVOY28EVINTAGERED/VIVOY28EVINTAGERED1.jpg";

import VIVOY200EBLACKDIAMOND1 from "assets/vivo/VIVOY200E/VIVOY200EBLACKDIAMOND/VIVOY200EBLACKDIAMOND1.jpg";

import VIVOX200NATURALGREEN1 from "assets/vivo/VIVOX200/VIVOX200NATURALGREEN/VIVOX200NATURALGREEN1.jpeg";

import VIVOX200PROTITANIUMGREY1 from "assets/vivo/VIVOX200PRO/VIVOX200TITANIUMGREY/VIVOX200PROTITANIUMGREY1.jpg";

import VIVOY295GGLACIERBLUE1 from "assets/vivo/VIVOY29/VIVOY295GGLACIERBLUE/VIVOY295GGLACIERBLUE1.jpeg";

import VIVOV50ROSERED from "assets/vivo/VIVOV50/VIVOV5OROSERED/VIVOV50ROSERED1.jpeg";

//samsung

import SAMSUNGA26AWESOMEMINT1 from "assets/samsung/SAMSUNGA26/SAMSUNGA26AWESOMEMINT/SAMSUNGA26AWESOMEMINT1.jpeg";

import SAMSUNGA56AWESOMEOLIVE1 from "assets/samsung/SAMSUNGA56/SAMSUNGA56AWESOMEOLIVE/SAMSUNGA56AWESOMEOLIVE1.jpg";

import SAMSUNGA36AWESOMELAVENDER1 from "assets/samsung/SAMSUNGA36/SAMSUNGA36AWESOMELAVENDER/SAMSUNGA36AWESOMELAVENDER1.jpg";

import SAMSUNGA065GLIGHTGREEN1 from "assets/samsung/SAMSUNGA065G/SAMSUNGA065GLIGHTGREEN/SAMSUNGA065GLIGHTGREEN1.jpeg";

import SAMSUNGA165GGOLD1 from "assets/samsung/SASMSUNGA165G/SAMSUNGA165GGOLD/SAMSUNGA165GGOLD1.jpeg";

import SAMSUNGF05TWILIGHTBLUE1 from "assets/samsung/SAMSUNGF05/SAMSUNGF05TWILIGHTBLUE/SAMSUNGF05TWILIGHTBLUE1.jpeg";

import SASMSUNGA165GBLUEBLACK1 from "assets/samsung/SASMSUNGA165G/SASMSUNGA165GBLUEBLACK/SASMSUNGA165GBLUEBLACK1.jpeg";

import A05SBLACK1 from "assets/samsung/A05S/A05SBLACK/A05SBLACK1.jpeg";

import SAMSUNGA06BLACK1 from "assets/samsung/SAMSUNGA06/SAMSUNGA06BLACK/SAMSUNGA06BLACK1.jpg";

import SAMSUNGA35AWESOMEICEBLUE1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMEICEBLUE/SAMSUNGA35AWESOMEICEBLUE1.jpg";

import SAMSUNGA35AWESOMELILAC1 from "assets/samsung/SAMSUNGA35/SAMSUNGA35AWESOMELILAC/SAMSUNGA35AWESOMELILAC1.jpg";

import SAMSUNGA55AWESOMENAVY1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55AWESOMENAVY/SAMSUNGA55AWESOMENAVY1.jpg";

import SAMSUNGA55BLUE1 from "assets/samsung/SAMSUNGA55/SAMSUNGA55BLUE/SAMSUNGA55BLUE1.jpg";

import SAMSUNGA145GBLACK1 from "assets/samsung/SAMSUNGA145G/SAMSUNGA145GBLACK/SAMSUNGA145GBLACK1.jpg";

import SAMSUNGF15ASHBLACK1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15ASHBLACK/SAMSUNGF15ASHBLACK1.jpg";

import SAMSUNGF15GROOVYVIOLET1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15GROOVYVIOLET/SAMSUNGF15GROOVYVIOLET1.jpg";

import SAMSUNGF15JAZZYGREEN1 from "assets/samsung/SAMSUNGF15/SAMSUNGF15JAZZYGREEN/SAMSUNGF15JAZZYGREEN1.jpg";

import SAMSUNGM14SPPHIREBLUE1 from "assets/samsung/SAMSUNGM14/SAMSUNGM14SPPHIREBLUE/SAMSUNGM14SPPHIREBLUE1.jpg";

import SAMSUNGS245GMARBLEGRAY1 from "assets/samsung/SAMSUNGS245G/SAMSUNGS245GMARBLEGRAY/SAMSUNGS245GMARBLEGRAY1.jpg";

import SAMSUNGS24PLUS5GCOBALTVIOLET1 from "assets/samsung/SAMSUNGS24PLUS5G/SAMSUNGS24PLUS5GCOBALTVIOLET/SAMSUNGS24PLUS5GCOBALTVIOLET1.jpeg";

import SAMSUNGS24ULTRA5GTITANIUMGRAY1 from "assets/samsung/SAMSUNGS24ULTRA5G/SAMSUNGS24ULTRA5GTITANIUMGRAY/SAMSUNGS24ULTRA5GTITANIUMGRAY1.jpg";

import SAMSUNGS24FE5GBLUE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GBLUE/SAMSUNGS24FE5GBLUE1.jpg";

import SAMSUNGS24FE5GGRAPHITE1 from "assets/samsung/SAMSUNGS24FE5G/SAMSUNGS24FE5GGRAPHITE/SAMSUNGS24FE5GGRAPHITE1.jpg";

import SAMSUNGS25ICYBLUE1 from "assets/samsung/SAMSUNGS25/SAMSUNGS25ICYBLUE/SAMSUNGS25ICYBLUE1.jpeg";

import SASMUNGS25PLUSNAVY1 from "assets/samsung/SAMSUNGS25PLUS/SASMUNGS25PLUSNAVY/SASMUNGS25PLUSNAVY1.jpeg";

import SAMSUNGS25ULTRATITANIUMBLACK1 from "assets/samsung/SAMSUNGS25ULTRA/SAMSUNGS25ULTRATITANIUMBLACK/SAMSUNGS25ULTRATITANIUMBLACK1.jpeg";

//tecno

import TECNOCAMON20ARTEDITION1 from "assets/tecno/TECNOCAMON20/TECNOCAMON20ARTEDITION/TECNOCAMON20ARTEDITION1.jpg";

import TECNOCAMON20PROSERENITYBLUE1 from "assets/tecno/TECNOCAMON20PRO/TECNOCAMON20PROSERENITYBLUE/TECNOCAMON20PROSERENITYBLUE1.jpg";

import TECNOCAMON30BASALTICDARK1 from "assets/tecno/TECNOCAMON30/TECNOCAMON30BASALTICDARK/TECNOCAMON30BASALTICDARK1.jpg";

import TECNOCAMON30PREMIERLAVABLACK1 from "assets/tecno/TECNOCAMON30PREMIER/TECNOCAMON30PREMIERLAVABLACK/TECNOCAMON30PREMIERLAVABLACK1.jpg";

import TECNOPHANTOMX2MOONLIGHTSILVER1 from "assets/tecno/TECNOPHANTOMX2/TECNOPHANTOMX2MOONLIGHTSILVER/TECNOPHANTOMX2MOONLIGHTSILVER1.jpg";

import TECNOPHANTOMX2PROMARSORANGE1 from "assets/tecno/TECNOPHANTOMX2PRO/TECNOPHANTOMX2PROMARSORANGE/TECNOPHANTOMX2PROMARSORANGE1.jpg";

import TECNOPOVA6NEOAURORACLOUD1 from "assets/tecno/TECNOPOVA6NEO/TECNOPOVA6NEOAURORACLOUD/TECNOPOVA6NEOAURORACLOUD1.jpeg";

import TECNOPOVA6PROCOMETGREEN1 from "assets/tecno/TECNOPOVA6PRO/TECNOPOVA6PROCOMETGREEN/TECNOPOVA6PROCOMETGREEN1.jpeg";

import TECNOSPARK30CAURORCLOUD1 from "assets/tecno/TECNOSPARK30C/TECNOSPARK30CAURORCLOUD/TECNOSPARK30CAURORCLOUD1.jpeg";

//IQOO

import IQOONEO9PROFIERYRED1 from "assets/iqoo/IQOONEO9PRO/IQOONEO9PROFIERYRED/IQOONEO9PROFIERYRED1.jpeg";

import IQOOZ9GRAPHENEBLUE1 from "assets/iqoo/IQOOZ9/IQOOZ9GRAPHENEBLUE/IQOOZ9GRAPHENEBLUE1.jpeg";

import IQOOZ9LITEMOCHABROWN1 from "assets/iqoo/IQOOZ9LITE/IQOOZ9LITEMOCHABROWN/IQOOZ9LITEMOCHABROWN1.jpg";

import IQOOZ9SONYXGREEN1 from "assets/iqoo/IQOOZ9S/IQOOZ9SONYXGREEN/IQOOZ9SONYXGREEN1.jpeg";

import IQOOZ9XSTORMGREY1 from "assets/iqoo/IQOOZ9X/IQOOZ9XSTORMGREY/IQOOZ9XSTORMGREY1.jpeg";

import IQOOZ9SPROLUXEMARBLE3 from "assets/iqoo/IQOOZ9SPRO/IQOOZ9SPROLUXEMARBLE/IQOOZ9SPROLUXEMARBLE3.jpeg";

//MOTOROLA

import MOTOROLAG45VIVAMAGENTA1 from "assets/motorola/MOTOROLAG45/MOTOROLAG45VIVAMAGENTA/MOTOROLAG45VIVAMAGENTA1.jpeg";

import MOTOROLAG85COBALTBLUE1 from "assets/motorola/MOTOROLAG85/MOTOROLAG85COBALTBLUE/MOTOROLAG85COBALTBLUE1.jpeg";

import MOTOROLAG64ICELILAC1 from "assets/motorola/MOTOROLAG64/MOTOROLAG64ICELILAC/MOTOROLAG64ICELILAC1.jpeg";

import MOTOROLAEDGE50NEOPANTONELATTE1 from "assets/motorola/MOTOROLAEDGE50NEO/MOTOROLAEDGE50NEOPANTONELATTE/MOTOROLAEDGE50NEOPANTONELATTE1.jpeg";

import MOTOROLAEDGE50FUSIONFORESTGREEN1 from "assets/motorola/MOTOROLAEDGE50FUSION/MOTOROLAEDGE50FUSIONFORESTGREEN/MOTOROLAEDGE50FUSIONFORESTGREEN1.jpeg";

import MOTOROLAEDGE50PEACHFUZZ1 from "assets/motorola/MOTOROLAEDGE50/MOTOROLAEDGE50PEACHFUZZ/MOTOROLAEDGE50PEACHFUZZ1.jpeg";

import MOTOROLAEDGE50PROCANEELBAY1 from "assets/motorola/MOTOROLAEDGE50PRO/MOTOROLAEDGE50PROCANEELBAY/MOTOROLAEDGE50PROCANEELBAY1.jpeg";

//Poco

import POCOC75SILVERSTARDUST1 from "assets/poco/POCOC75/POCOC75SILVERSTARDUST/POCOC75SILVERSTARDUST1.jpeg";

import POCOM7PROLAVENDERFROST1 from "assets/poco/POCOM7PRO/POCOM7PROLAVENDERFROST/POCOM7PROLAVENDERFROST1.jpeg";

export const ALLPHONES = [
  //VIVO PHONES

  {
    id: "VIVOY391",
    name: "VIVO Y39 5G 8/128 ",
    brand: "VIVO",
    prebook: false,
    series: "Y39",
    image: VIVOY39GALAXYPURPLE1,
    launchDate: "26-03-2025",
    networkType: "5G",
    salesPrice: 16999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 21999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.68″ (16.97 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },
  {
    id: "VIVOY392",
    name: "VIVO Y39 5G 8/256 ",
    brand: "VIVO",
    prebook: false,
    series: "Y39",
    image: VIVOY39GALAXYPURPLE1,
    launchDate: "26-03-2025",
    networkType: "5G",
    salesPrice: 18999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 23999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 4 Gen 2 Octa core ",
      Display: "6.68″ (16.97 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "6500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
    },
  },

  {
    id: "VIVOY181",
    name: "VIVO Y18 4G 4/64 ",
    brand: "VIVO",
    prebook: false,
    series: "Y18",
    image: VIVOY18GEMGREEN1,
    launchDate: "03-05-2024",
    networkType: "4G",
    salesPrice: 7999,
    specialOffer: true,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 13999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
      Processor: "MediaTek Helio G85 Octa core ",
    },
  },

  {
    id: "VIVOY182",
    name: "VIVO Y18 4G 4/128 ",
    brand: "VIVO",
    prebook: false,
    series: "Y18",
    image: VIVOY18GEMGREEN1,
    launchDate: "03-05-2024",
    networkType: "4G",
    salesPrice: 8499,
    specialOffer: true,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 14999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
      Processor: "MediaTek Helio G85 Octa core ",
    },
  },

  {
    id: "VIVOY18T1",
    name: "VIVO Y18T 4G 4/128 ",
    brand: "VIVO",
    prebook: false,
    series: "Y18T",
    image: VIVOY18TSPACEBLACK,
    launchDate: "12-11-2024",
    networkType: "4G",
    salesPrice: 8499,
    specialOffer: true,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 13999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
      Processor: "Unisoc Octa core ",
    },
  },
  {
    id: "VIVO1",
    name: "VIVO v40 5G 8/256 ",
    brand: "VIVO",
    prebook: false,
    series: "V40",
    image: VIVOV40LOTUSPURPLE1,
    launchDate: "03-07-2024",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 42999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },

  {
    id: "VIVO2",
    name: "VIVO v40 5G 8/128 ",
    brand: "VIVO",
    prebook: false,
    series: "V40",
    image: VIVOV40GANGESBLUE1,
    launchDate: "03-07-2024",
    networkType: "5G",
    salesPrice: 34999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 39999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },
  {
    id: "VIVO3",
    name: "VIVO v40e 5G 8/128 ",
    brand: "VIVO",
    prebook: false,
    series: "V40e",
    image: VIVOV40EMINTGREEN1,
    launchDate: "02-10-2024",
    networkType: "5G",
    salesPrice: 26999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 33999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Octa core ",
    },
  },
  {
    id: "VIVO4",
    name: "VIVO v40e 5G 8/256 ",
    brand: "VIVO",
    prebook: false,
    series: "V40e",
    image: VIVOV40EMINTGREEN1,
    launchDate: "02-10-2024",
    networkType: "5G",
    salesPrice: 28999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 35999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Octa core ",
    },
  },

  {
    id: "VIVO5",
    name: "VIVO Y200e 5G 8/128 ",
    brand: "VIVO",
    image: VIVOY200EBLACKDIAMOND1,
    launchDate: "22-02-2024",
    networkType: "5G",
    salesPrice: 19999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: false,
    mrp: 25999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },

  {
    id: "VIVO12",
    name: "VIVO Y28E 5G 4/64 ",
    brand: "VIVO",
    prebook: false,
    series: "Y28E",
    image: VIVOY28EBREEZEGREEN1,
    launchDate: "08-07-2024",
    networkType: "5G",
    salesPrice: 9999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4,
    newArrival: false,
    mrp: 14999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "13+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "VIVO13",
    name: "VIVO Y28E 5G 4/128 ",
    brand: "VIVO",
    prebook: false,
    series: "Y28E",
    image: VIVOY28EVINTAGERED1,
    launchDate: "08-07-2024",
    networkType: "5G",
    salesPrice: 10999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: false,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "13+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "VIVOY28E1",
    name: "VIVO Y28E 5G 4/64 WITH CHARGER ",
    brand: "VIVO",
    prebook: false,
    series: "Y28EWITHCHARGER",
    image: VIVOY28EVINTAGERED1,
    launchDate: "08-07-2024",
    networkType: "5G",
    salesPrice: 10499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: false,
    mrp: 14999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "13+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "VIVOY28E2",
    name: "VIVO Y28E 5G 4/128 WITH CHARGER ",
    brand: "VIVO",
    prebook: false,
    series: "Y28EWITHCHARGER",
    image: VIVOY28EVINTAGERED1,
    launchDate: "08-07-2024",
    networkType: "5G",
    salesPrice: 11499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: false,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "13+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "VIVO14",
    name: "VIVO Y18I 4G 4/64 ",
    brand: "VIVO",
    image: VIVOY18IPACEBLACK1,
    launchDate: "23-08-2024",
    networkType: "4G",
    salesPrice: 7999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: false,
    mrp: 11999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: " 6.56-inch (720x1612) 90Hz ",
      "Rear Camera": "13MP + 0.08MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh  ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android 14 ",
      Processor: " Unisoc T612 octa-core ",
    },
  },

  {
    id: "VIVO15",
    name: "VIVO Y300 5G 8/128",
    brand: "VIVO",
    prebook: false,
    series: "Y300",
    image: VIVOY3005GPHANTOMPURPLE1,
    launchDate: "21-11-2024",
    networkType: "5G",
    salesPrice: 21999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.3,
    newArrival: true,
    mrp: 26999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },

  {
    id: "VIVO16",
    name: "VIVO Y300 5G 8/256",
    brand: "VIVO",
    prebook: false,
    series: "Y300",
    image: VIVOY3005GPHANTOMPURPLE1,
    launchDate: "21-11-2024",
    networkType: "5G",
    salesPrice: 23999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 28999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256  GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },

  {
    id: "VIVO17",
    name: "VIVO X200 5G 12/256",
    brand: "VIVO",
    prebook: false,
    series: "VIVOX200",
    image: VIVOX200NATURALGREEN1,
    launchDate: "18-12-2024",
    networkType: "5G",
    salesPrice: 65999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.8,
    newArrival: true,
    mrp: 74999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5800 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 9400 Octa core ",
    },
  },

  {
    id: "VIVO18",
    name: "VIVO X200 5G 16/512",
    brand: "VIVO",
    prebook: false,
    series: "VIVOX200",
    image: VIVOX200NATURALGREEN1,
    launchDate: "18-12-2024",
    networkType: "5G",
    salesPrice: 71999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.8,
    newArrival: true,
    mrp: 80999,
    ram: 16,
    storage: 512,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "16 GB | 512 GB ",
      Battery: "5800 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 9400 Octa core ",
    },
  },

  {
    id: "VIVO19",
    name: "VIVO X200 PRO 5G 16/512",
    brand: "VIVO",
    prebook: false,
    series: "VIVOX200PRO",
    image: VIVOX200PROTITANIUMGREY1,
    launchDate: "18-12-2024",
    networkType: "5G",
    salesPrice: 94999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.8,
    newArrival: true,
    mrp: 101999,
    ram: 16,
    storage: 512,
    specifications: {
      Display: "6.78″ (17.22 cm) 120Hz LTPO AMOLED ",
      "Rear Camera": "50+50+200 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "16 GB | 512 GB ",
      Battery: "6000 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 9400 Octa core ",
    },
  },
  {
    id: "VIVO20",
    name: "VIVO Y29 5G 4/128",
    brand: "VIVO",
    prebook: false,
    series: "VIVOY29",
    image: VIVOY295GGLACIERBLUE1,
    launchDate: "24-12-2024",
    networkType: "5G",
    salesPrice: 13999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 17999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.68″ IPS LCD, 90Hz  ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Mediatek Dimensity 6300 (6 nm) ",
    },
  },
  {
    id: "VIVO21",
    name: "VIVO Y29 5G 6/128",
    brand: "VIVO",
    prebook: false,
    series: "VIVOY29",
    image: VIVOY295GGLACIERBLUE1,
    launchDate: "24-12-2024",
    networkType: "5G",
    salesPrice: 15499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 19499,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.68″ IPS LCD, 90Hz  ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Mediatek Dimensity 6300 (6 nm) ",
    },
  },
  {
    id: "VIVO22",
    name: "VIVO Y29 5G 8/128",
    brand: "VIVO",
    prebook: false,
    series: "VIVOY29",
    image: VIVOY295GGLACIERBLUE1,
    launchDate: "24-12-2024",
    networkType: "5G",
    salesPrice: 16999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 20999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.68″ IPS LCD, 90Hz  ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Mediatek Dimensity 6300 (6 nm) ",
    },
  },
  {
    id: "VIVO23",
    name: "VIVO Y29 5G 8/256",
    brand: "VIVO",
    prebook: false,
    series: "VIVOY29",
    image: VIVOY295GGLACIERBLUE1,
    launchDate: "24-12-2024",
    networkType: "5G",
    salesPrice: 18999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.68″ IPS LCD, 90Hz  ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Mediatek Dimensity 6300 (6 nm) ",
    },
  },
  {
    id: "VIVO24",
    name: "VIVO V50 5G 8/128",
    brand: "VIVO",
    prebook: false,
    series: "VIVOV50",
    image: VIVOV50ROSERED,
    launchDate: "17-02-2025",
    networkType: "5G",
    salesPrice: 34999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 39999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.78″ (17.22 cm) AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },
  {
    id: "VIVO25",
    name: "VIVO V50 5G 8/256",
    brand: "VIVO",
    prebook: false,
    series: "VIVOV50",
    image: VIVOV50ROSERED,
    launchDate: "17-02-2025",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 42999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.78″ (17.22 cm) AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "6000 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },
  {
    id: "VIVO26",
    name: "VIVO V50 5G 12/512",
    brand: "VIVO",
    prebook: false,
    series: "VIVOV50",
    image: VIVOV50ROSERED,
    launchDate: "17-02-2025",
    networkType: "5G",
    salesPrice: 40999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 48999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.78″ (17.22 cm) AMOLED ",
      "Rear Camera": "50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "6000 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },
  {
    id: "VIVO27",
    name: "VIVO Y18 4G 4/64 ",
    brand: "VIVO",
    image: VIVOY18IPACEBLACK1,
    launchDate: "23-08-2024",
    networkType: "4G",
    salesPrice: 7999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: false,
    mrp: 11999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: " 6.56-inch (720x1612) 90Hz ",
      "Rear Camera": "13MP + 0.08MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh  ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android 14 ",
      Processor: " Unisoc T612 octa-core ",
    },
  },

  //tecno
  {
    id: "TECNO1",
    name: "TECNO CAMON 20 4G",
    image: TECNOCAMON20ARTEDITION1,
    brand: "TECNO",
    launchDate: "09-05-2023",
    networkType: "4G",
    salesPrice: 10499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 13999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 60Hz AMOLED ",
      "Rear Camera": "64+2+0.08 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
      Processor: "MediaTek Helio G85 Octa core ",
    },
  },

  {
    id: "TECNO2",
    name: "TECNO CAMON 20 PRO 5G",
    brand: "TECNO",
    image: TECNOCAMON20PROSERENITYBLUE1,
    launchDate: "09-05-2023",
    networkType: "5G",
    salesPrice: 17999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 24999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "64+2+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 8050 Octa core ",
    },
  },

  {
    id: "TECNO3",
    name: "TECNO CAMON 30 5G",
    brand: "TECNO",
    image: TECNOCAMON30BASALTICDARK1,
    launchDate: "02-04-2024",
    networkType: "5G",
    salesPrice: 22999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 27999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.78″ (17.22 cm) 120Hz LTPS AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7020 Octa core ",
    },
  },

  {
    id: "TECNO4",
    name: "TECNO CAMON 30 PREMIER 5G",
    brand: "TECNO",
    launchDate: "15-11-2024",
    networkType: "5G",
    salesPrice: 39999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.6,
    newArrival: true,
    mrp: 47999,
    image: TECNOCAMON30PREMIERLAVABLACK1,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz LTPO AMOLED ",
      "Rear Camera": "50+50+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 70W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 8200 Octa core ",
    },
  },

  {
    id: "TECNO5",
    name: "TECNO PHANTOM X2 5G",
    brand: "TECNO",
    launchDate: "09-01-2023",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.8,
    newArrival: true,
    mrp: 51999,
    ram: 8,
    storage: 256,
    image: TECNOPHANTOMX2MOONLIGHTSILVER1,
    specifications: {
      Display: "6.8″ (17.27 cm) 120Hz AMOLED ",
      "Rear Camera": "64+13+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5160 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v12 ",
      Processor: "MediaTek Dimensity 9000 Octa core ",
    },
  },

  {
    id: "TECNO6",
    name: "TECNO PHANTOM X2 PRO 5G",
    brand: "TECNO",
    launchDate: "17-01-2023",
    networkType: "5G",
    salesPrice: 44999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 61999,
    ram: 12,
    image: TECNOPHANTOMX2PROMARSORANGE1,
    storage: 256,
    specifications: {
      Display: "6.8″ (17.27 cm) 120Hz AMOLED ",
      "Rear Camera": "50+50+13 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5160 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 4G",
      OS: "Android v12 ",
      Processor: "MediaTek Dimensity 9000 Octa core ",
    },
  },

  {
    id: "TECNO7",
    name: "TECNO POVA 6 NEO 5G",
    brand: "TECNO",
    launchDate: "04-04-2024",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 6,
    image: TECNOPOVA6NEOAURORACLOUD1,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "108 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "TECNO8",
    name: "TECNO POVA 6 PRO 5G",
    brand: "TECNO",
    launchDate: "26-02-2024",
    networkType: "5G",
    salesPrice: 21999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 24999,
    ram: 12,
    image: TECNOPOVA6PROCOMETGREEN1,
    storage: 256,
    specifications: {
      Display: "6.78″ (17.22 cm) 120Hz AMOLED ",
      "Rear Camera": "108+2+0.08 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "6000 mAh | 70W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6080 Octa core ",
    },
  },

  {
    id: "TECNO9",
    name: "TECNO SPARK 30C 5G",
    brand: "TECNO",
    launchDate: "02-10-2024",
    networkType: "5G",
    salesPrice: 10499,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 11999,
    ram: 4,
    storage: 128,
    image: TECNOSPARK30CAURORCLOUD1,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "48 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  //MI Phones
  {
    id: "MI1",
    name: "MI 13C 5G 4/128",
    brand: "MI",
    prebook: false,
    series: "MI13C",
    image: REDMI13CBLACK1,
    launchDate: "06-12-2023",
    networkType: "5G",
    salesPrice: 10499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 13999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },
  {
    id: "MI2",
    name: "MI 13C 5G 6/128",
    brand: "MI",
    prebook: false,
    series: "MI13C",
    image: REDMI13CGREEN1,
    launchDate: "06-12-2023",
    networkType: "5G",
    salesPrice: 11999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "MI3",
    name: "MI 13C 5G 8/256",
    brand: "MI",
    prebook: false,
    series: "MI13C",
    image: REDMI13CSILVER1,
    launchDate: "06-12-2023",
    networkType: "5G",
    salesPrice: 13999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50+0.08 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "MI4",
    name: "MI NOTE 13 5G 6/128",
    brand: "MI",
    prebook: false,
    series: "MINOTE135G",
    image: REDMINOTE13WHITE1,
    launchDate: "04-01-2024",
    networkType: "5G",
    salesPrice: 15999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 20999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 6080 Octa core ",
    },
  },
  {
    id: "MI5",
    name: "MI NOTE 13 5G 12/256",
    brand: "MI",
    prebook: false,
    series: "MINOTE135G",
    image: REDMINOTE13PURPLE1,
    launchDate: "04-01-2024",
    networkType: "5G",
    salesPrice: 18999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 24999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 6080 Octa core ",
    },
  },
  {
    id: "MI6",
    name: "MI NOTE 13 5G 8/256",
    brand: "MI",
    prebook: false,
    series: "MINOTE135G",
    image: REDMINOTE13GOLD1,
    launchDate: "04-01-2024",
    networkType: "5G",
    salesPrice: 17999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "108+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 6080 Octa core ",
    },
  },

  {
    id: "MI7",
    name: "MI 13 5G 6/128",
    brand: "MI",
    prebook: false,
    series: "MI135G",
    image: REDMI135GBLACKDIAMOND1,
    launchDate: "03-06-2024",
    networkType: "5G",
    salesPrice: 12499,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 17999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.79″ (17.25 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5030 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },
  {
    id: "MI8",
    name: "MI 13 5G 8/128",
    brand: "MI",
    prebook: false,
    series: "MI135G",
    image: REDMI135GBLACKDIAMOND1,
    launchDate: "03-06-2024",
    networkType: "5G",
    salesPrice: 13999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 19999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.79″ (17.25 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5030 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },

  {
    id: "MI9",
    name: "MI Note 13 Pro Plus 5G 8/256",
    brand: "MI",
    prebook: false,
    series: "MINOTE13PROPLUS",
    image: REDMINOTE13PROPLUS5GFUSIONBLACK1,
    launchDate: "21-09-2023",
    networkType: "5G",
    salesPrice: 24999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 33999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "200+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
      Processor: "MediaTek Dimensity 7200 Ultra Octa core ",
    },
  },
  {
    id: "MI10",
    name: "MI Note 13 Pro Plus 5G 12/256",
    brand: "MI",
    prebook: false,
    series: "MINOTE13PROPLUS",
    image: REDMINOTE13PROPLUS5GFUSIONBLACK1,
    launchDate: "21-09-2023",
    networkType: "5G",
    salesPrice: 29999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 26999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "200+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
      Processor: "MediaTek Dimensity 7200 Ultra Octa core ",
    },
  },
  {
    id: "MI11",
    name: "MI Note 13 Pro Plus 5G 12/512",
    brand: "MI",
    prebook: false,
    series: "MINOTE13PROPLUS",
    image: REDMINOTE13PROPLUS5GFUSIONBLACK1,
    launchDate: "21-09-2023",
    networkType: "5G",
    salesPrice: 28999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 37999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "200+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
      Processor: "MediaTek Dimensity 7200 Ultra Octa core ",
    },
  },

  {
    id: "MI12",
    name: "MI Note 14 5G 6/128",
    brand: "MI",
    prebook: false,
    series: "MINOTE14",
    image: REDMINOTE14PHANTOMPURPLE1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 16999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 21999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz G-OLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5110 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7025 Ultra Octa core ",
    },
  },

  {
    id: "MI13",
    name: "MI Note 14 5G 8/128",
    brand: "MI",
    prebook: false,
    series: "MINOTE14",
    image: REDMINOTE14PHANTOMPURPLE1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 17999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz G-OLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5110 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7025 Ultra Octa core ",
    },
  },

  {
    id: "MI14",
    name: "MI Note 14 5G 8/256",
    brand: "MI",
    prebook: false,
    series: "MINOTE14",
    image: REDMINOTE14PHANTOMPURPLE1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 19999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 24999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz G-OLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5110 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7025 Ultra Octa core ",
    },
  },

  {
    id: "MI15",
    name: "MI Note 14 Pro 5G 8/128",
    brand: "MI",
    prebook: false,
    series: "MINOTE14PRO",
    image: REDMINOTE14PROIVYGREEN1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 24999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 28999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Ultra Octa core ",
    },
  },

  {
    id: "MI16",
    name: "MI Note 14 Pro 5G 8/256",
    brand: "MI",
    prebook: false,
    series: "MINOTE14PRO",
    image: REDMINOTE14PROIVYGREEN1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 26999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 30999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Ultra Octa core ",
    },
  },

  {
    id: "MI17",
    name: "MI Note 14 Pro Plus 5G 8/128",
    brand: "MI",
    prebook: false,
    series: "MINOTE14PROPLUS",
    image: REDMINOTE14PROPLUSSPECTREBLUE1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 30999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 34999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6200 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "Snapdragon 7s Gen 3 Octa core ",
    },
  },

  {
    id: "MI18",
    name: "MI Note 14 Pro Plus 5G 8/256",
    brand: "MI",
    prebook: false,
    series: "MINOTE14PROPLUS",
    image: REDMINOTE14PROPLUSSPECTREBLUE1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 32999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 36999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "6200 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "Snapdragon 7s Gen 3 Octa core ",
    },
  },

  {
    id: "MI19",
    name: "MI Note 14 Pro Plus 5G 12/512",
    brand: "MI",
    prebook: false,
    series: "MINOTE14PROPLUS",
    image: REDMINOTE14PROPLUSSPECTREBLUE1,
    launchDate: "13-12-2024",
    networkType: "5G",
    salesPrice: 35999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 39999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "6200 mAh | 90W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "Snapdragon 7s Gen 3 Octa core ",
    },
  },
  {
    id: "MI20",
    name: "MI 14C 5G 4/64",
    brand: "MI",
    prebook: false,
    series: "MI14C",
    image: REDMI14CSTARLIGHTBLUE1,
    launchDate: "10-01-2025",
    networkType: "5G",
    salesPrice: 9999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 13999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.88″ (17.48 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5160 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },
  {
    id: "MI21",
    name: "MI 14C 5G 4/128",
    brand: "MI",
    prebook: false,
    series: "MI14C",
    image: REDMI14CSTARLIGHTBLUE1,
    launchDate: "10-01-2025",
    networkType: "5G",
    salesPrice: 10999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 14999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.88″ (17.48 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5160 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },
  {
    id: "MI22",
    name: "MI 14C 5G 6/128",
    brand: "MI",
    prebook: false,
    series: "MI14C",
    image: REDMI14CSTARLIGHTBLUE1,
    launchDate: "10-01-2025",
    networkType: "5G",
    salesPrice: 11999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 14999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.88″ (17.48 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5160 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4 Gen 2 Octa core ",
    },
  },

  //OPPO PHONES
  {
    id: "OPPOF291",
    name: "OPPO F29 5G 8/128 ",
    series: "F29",
    brand: "OPPO",
    image: OPPOF29GLACIERBLUE1,
    launchDate: "20-03-2025",
    networkType: "5G",
    salesPrice: 23999,
    specialOffer: false,
    trendingPhone: true,
    rating: 3.5,
    newArrival: true,
    mrp: 28999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "Snapdragon 6 Gen 1 Octa core ",
      Display: "6.7″ (17.02 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6500 mAh | 45W Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v15 ",
    },
  },
  {
    id: "OPPOF292",
    name: "OPPO F29 5G 8/256 ",
    brand: "OPPO",
    series: "F29",
    image: OPPOF29GLACIERBLUE1,
    launchDate: "20-03-2025",
    networkType: "5G",
    salesPrice: 25999,
    specialOffer: false,
    trendingPhone: true,
    rating: 3.5,
    newArrival: true,
    mrp: 30999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "Snapdragon 6 Gen 1 Octa core ",
      Display: "6.7″ (17.02 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "6500 mAh | 45W Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v15 ",
    },
  },
  {
    id: "OPPOF29PRO1",
    name: "OPPO F29 PRO 5G 8/128 ",
    brand: "OPPO",
    series: "F29PRO",
    image: OPPOF29PROMARBLEWHITE1,
    launchDate: "20-03-2025",
    networkType: "5G",
    salesPrice: 27999,
    specialOffer: false,
    trendingPhone: true,
    rating: 3.5,
    newArrival: true,
    mrp: 31999,
    ram: 8,
    storage: 128,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.7″ (17.02 cm) AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 80W Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v15 ",
    },
  },
  {
    id: "OPPOF29PRO2",
    name: "OPPO F29 PRO 5G 8/256 ",
    brand: "OPPO",
    series: "F29PRO",
    image: OPPOF29PROMARBLEWHITE1,
    launchDate: "20-03-2025",
    networkType: "5G",
    salesPrice: 29999,
    specialOffer: false,
    trendingPhone: true,
    rating: 3.5,
    newArrival: true,
    mrp: 34999,
    ram: 8,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.7″ (17.02 cm) AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "6000 mAh | 80W Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v15 ",
    },
  },
  {
    id: "OPPOF29PRO3",
    name: "OPPO F29 PRO 5G 12/256 ",
    brand: "OPPO",
    series: "F29PRO",
    image: OPPOF29PROMARBLEWHITE1,
    launchDate: "20-03-2025",
    networkType: "5G",
    salesPrice: 31999,
    specialOffer: false,
    trendingPhone: true,
    rating: 3.5,
    newArrival: true,
    mrp: 35999,
    ram: 12,
    storage: 256,
    specifications: {
      Processor: "MediaTek Dimensity 7300 Octa core ",
      Display: "6.7″ (17.02 cm) AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "6000 mAh | 80W Fast Charging ",
      Network: "Single SIM: 5G",
      OS: "Android v15 ",
    },
  },

  {
    id: "OPPO3",
    name: "OPPO A77 4G 4/128 ",
    brand: "OPPO",
    image: OPPOA77ORANGE1,
    launchDate: "03-08-2022",
    networkType: "4G",
    salesPrice: 15999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 19999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 60Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v12",
      Processor: "MediaTek Helio G35 Octa core ",
    },
  },

  {
    id: "OPPO5",
    name: "OPPO K12X 5G 8/256 ",
    brand: "OPPO",
    prebook: false,
    series: "K12X",
    image: OPPOK12XVOILET1,
    launchDate: "29-07-2024",
    networkType: "5G",
    salesPrice: 15999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 18999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "32+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "OPPO6",
    name: "OPPO K12X 5G 6/128 ",
    brand: "OPPO",
    prebook: false,
    series: "K12X",
    image: OPPOK12XBLUE1,
    launchDate: "29-07-2024",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 16999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "32+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "OPPO12",
    name: "OPPO F27 5G 8/256 ",
    brand: "OPPO",
    prebook: false,
    series: "F275G",
    image: OPPOF27AMBERORANGE1,
    launchDate: "20-08-2024",
    salesPrice: 22999,
    specialOffer: true,
    trendingPhone: true,
    rating: 4.2,
    newArrival: true,
    mrp: 28999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz Flat OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core",
    },
  },
  {
    id: "OPPO13",
    name: "OPPO F27 5G 8/128",
    brand: "OPPO",
    prebook: false,
    series: "F275G",
    image: OPPOF27EMERALDGREEN1,
    launchDate: "20-08-2024",
    salesPrice: 20999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 26999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz Flat OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core",
    },
  },

  {
    id: "OPPO15",
    name: "OPPO A3 PRO 5G 8/128 ",
    brand: "OPPO",
    prebook: false,
    series: "A3PRO5G",
    image: OPPOA3PROMOONLIGHTPURPLE1,
    launchDate: "21-06-2024",
    networkType: "5G",
    salesPrice: 16999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 20999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "OPPO14",
    name: "OPPO A3 PRO 5G 8/256 ",
    brand: "OPPO",
    prebook: false,
    series: "A3PRO5G",
    image: OPPOA3PROSTARRYBLACK1,
    launchDate: "21-06-2024",
    networkType: "5G",
    salesPrice: 18999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "OPPO16",
    name: "OPPO A3X 5G 4/128 ",
    brand: "OPPO",
    prebook: false,
    series: "A3X5G",
    image: OPPOA3XSPARKLEBLACK1,
    launchDate: "02-08-2024",
    networkType: "5G",
    salesPrice: 12499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "8 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "OPPO17",
    name: "OPPO A3X 5G 4/64 ",
    brand: "OPPO",
    prebook: false,
    series: "A3X5G",
    image: OPPOA3XSPARKLEBLACK1,
    launchDate: "02-08-2024",
    networkType: "5G",
    salesPrice: 11499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 14999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "8 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "OPPO21",
    name: "OPPO RENO 13 5G 8/128 ",
    brand: "OPPO",
    series: "RENO13",
    image: RENO135GIVORYWHITE1,
    salesPrice: 37999,
    launchDate: "09-01-2025",
    networkType: "5G",
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    ram: 8,
    storage: 128,
    mrp: 41999,
    specifications: {
      Display: "6.59″ (16.74 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5600 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 8350 Octa core ",
    },
  },

  {
    id: "OPPO22",
    name: "OPPO RENO 13 5G 8/256 ",
    brand: "OPPO",
    series: "RENO13",
    image: RENO135GLUMINOUSWHITE1,
    salesPrice: 39999,
    launchDate: "09-01-2025",
    networkType: "5G",
    specialOffer: false,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    ram: 8,
    storage: 256,
    mrp: 43999,
    specifications: {
      Display: "6.59″ (16.74 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5600 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 8350 Octa core ",
    },
  },

  {
    id: "OPPO23",
    name: "OPPO  RENO 13 PRO 5G 12/256",
    brand: "OPPO",
    networkType: "5G",
    prebook: false,
    series: "RENO13PRO",
    image: RENO13PROMISTLAVENDER1,
    launchDate: "09-01-2025",
    salesPrice: 49999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    mrp: 54999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.83″ (17.35 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5800 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 8350 Octa core ",
    },
  },

  {
    id: "OPPO24",
    name: "OPPO  RENO 13 PRO 5G 12/512",
    brand: "OPPO",
    prebook: false,
    series: "RENO13PRO",
    networkType: "5G",
    image: RENO13PROMISTLAVENDER1,
    launchDate: "09-01-2025",
    salesPrice: 54999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 60999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.83″ (17.35 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5800 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 8350 Octa core ",
    },
  },
  {
    id: "OPPO25",
    name: "OPPO A3 5G 6/128",
    brand: "OPPO",
    prebook: false,
    series: "OPPOA3",
    image: OPPOA3OCEANBLUE1,
    launchDate: "19-08-2024",
    salesPrice: 14999,
    networkType: "5G",
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: false,
    mrp: 19999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5100 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  //REALME PHONES
  {
    id: "REALME1",
    name: "REALME C63 4G 4/64",
    brand: "REALME",
    prebook: false,
    series: "REALMEC63",

    image: REALMEC63JADEGREEN1,
    launchDate: "31-05-2024",
    salesPrice: 8499,
    specialOffer: false,
    networkType: "4G",
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 9999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
      Processor: "Unisoc Octa core ",
    },
  },

  {
    id: "REALME2",
    name: "REALME C63 4G 4/128",
    brand: "REALME",
    prebook: false,
    series: "REALMEC63",
    image: REALMEC63JADEGREEN1,
    launchDate: "31-05-2024",
    salesPrice: 8999,
    networkType: "4G",
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 10999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.74″ (17.12 cm) 90Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
      Processor: "Unisoc Octa core ",
    },
  },

  {
    id: "REALME3",
    name: "REALME 11 5G 8/256",
    brand: "REALME",
    prebook: false,
    series: "REALME11",
    image: REALME11GLORYGOLD1,
    launchDate: "31-07-2023",
    networkType: "5G",
    salesPrice: 19999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 22999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 67W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "REALME4",
    name: "REALME 12 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALME12",
    image: REALME12TWILIGHTPURPLE1,
    launchDate: "06-03-2024",
    networkType: "5G",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 20999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "108+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "REALME5",
    name: "REALME C65 5G 4/128",
    brand: "REALME",
    prebook: false,
    series: "REALMEC65",
    image: REALMEC65FEATHERGREEN1,
    launchDate: "26-04-2024",
    networkType: "5G",
    salesPrice: 10999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 13999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "REALME6",
    name: "REALME C65 5G 6/128",
    brand: "REALME",
    prebook: false,
    series: "REALMEC65",
    image: REALMEC65FEATHERGREEN1,
    launchDate: "26-04-2024",
    networkType: "5G",
    salesPrice: 11999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 14999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "REALME7",
    name: "REALME C65 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALMEC65",
    image: REALMEC65FEATHERGREEN1,
    launchDate: "26-04-2024",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 16999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "REALME8",
    name: "REALME C67 5G 4/128",
    brand: "REALME",
    prebook: false,
    series: "REALMEC67",
    image: REALMEC67SUNNYOASIS1,
    launchDate: "14-12-2023",
    networkType: "5G",
    salesPrice: 11999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 16999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "REALME9",
    name: "REALME C67 5G 6/128",
    brand: "REALME",
    prebook: false,
    series: "REALMEC67",
    image: REALMEC67SUNNYOASIS1,
    launchDate: "14-12-2023",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 17999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.72″ (17.07 cm) 120Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "REALME10",
    name: "REALME 12 PLUS 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALME12PLUS",
    image: REALME12PLUSNAVIGATORBEIGE1,
    launchDate: "06-03-2024",
    networkType: "5G",
    salesPrice: 20999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 23999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 67W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7050 Octa core ",
    },
  },

  {
    id: "REALME11",
    name: "REALME 12 PLUS 5G 8/256",
    brand: "REALME",
    prebook: false,
    series: "REALME12PLUS",
    image: REALME12PLUSNAVIGATORBEIGE1,
    launchDate: "06-03-2024",
    networkType: "5G",
    salesPrice: 18999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 25999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 67W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7050 Octa core ",
    },
  },

  {
    id: "REALME12",
    name: "REALME 13 PRO PLUS 5G 8/256",
    brand: "REALME",
    prebook: false,
    series: "REALME13PROPLUS",
    image: REALME13PROPLUSMONETPURPLE1,
    launchDate: "29-08-2024",
    networkType: "5G",
    salesPrice: 32999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 35999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz OLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5200 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7s Gen 2 Octa core ",
    },
  },

  {
    id: "REALME13",
    name: "REALME 13 PRO PLUS 5G 12/256",
    brand: "REALME",
    prebook: false,
    series: "REALME13PROPLUS",
    image: REALME13PROPLUSMONETPURPLE1,
    launchDate: "29-08-2024",
    networkType: "5G",
    salesPrice: 34999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 38999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz OLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5200 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7s Gen 2 Octa core ",
    },
  },

  {
    id: "REALME14",
    name: "REALME 13 PRO PLUS 5G 12/512",
    brand: "REALME",
    prebook: false,
    series: "REALME13PROPLUS",
    image: REALME13PROPLUSMONETPURPLE1,
    launchDate: "29-08-2024",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 40999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz OLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5200 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7s Gen 2 Octa core ",
    },
  },

  {
    id: "REALME15",
    name: "REALME 13 PRO 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALME13PRO",
    image: REALME13PROEMERALDGREEN1,
    launchDate: "30-07-2024",
    networkType: "5G",
    salesPrice: 26999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 28999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz OLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5200 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7s Gen 2 Octa core ",
    },
  },

  {
    id: "REALME16",
    name: "REALME 13 PRO 5G 8/256",
    brand: "REALME",
    prebook: false,
    series: "REALME13PRO",
    image: REALME13PROEMERALDGREEN1,
    launchDate: "30-07-2024",
    networkType: "5G",
    salesPrice: 28999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 30999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz OLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5200 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7s Gen 2 Octa core ",
    },
  },

  {
    id: "REALME17",
    name: "REALME 13 PRO 5G 12/512",
    brand: "REALME",
    prebook: false,
    series: "REALME13PRO",
    image: REALME13PROEMERALDGREEN1,
    launchDate: "30-07-2024",
    networkType: "5G",
    salesPrice: 31999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 34999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz OLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5200 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7s Gen 2 Octa core ",
    },
  },

  {
    id: "REALME18",
    name: "REALME 13 PLUS 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALME13PLUS",
    image: REALME13PLUSVICTORYGOLD1,
    launchDate: "29-08-2024",
    networkType: "5G",
    salesPrice: 22999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 25999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
    },
  },

  {
    id: "REALME19",
    name: "REALME 13 PLUS 5G 8/256",
    brand: "REALME",
    prebook: false,
    series: "REALME13PLUS",
    image: REALME13PLUSVICTORYGOLD1,
    launchDate: "29-08-2024",
    networkType: "5G",
    salesPrice: 24999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 27999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
    },
  },

  {
    id: "REALME20",
    name: "REALME 13 PLUS 5G 12/256",
    brand: "REALME",
    prebook: false,
    series: "REALME13PLUS",
    image: REALME13PLUSVICTORYGOLD1,
    launchDate: "29-08-2024",
    networkType: "5G",
    salesPrice: 26999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 29999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
    },
  },

  {
    id: "REALME21",
    name: "REALME 14X 5G 6/128",
    brand: "REALME",
    prebook: false,
    series: "REALME14X",
    image: REALME14XJEWELRED1,
    launchDate: "18-12-2024",
    networkType: "5G",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 16999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "6000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "REALME22",
    name: "REALME 14X 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALME14X",
    image: REALME14XJEWELRED1,
    launchDate: "18-12-2024",
    networkType: "5G",
    salesPrice: 15999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 17999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "REALME23",
    name: "REALME 14 PRO 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALME14PRO",
    image: REALME14PROJAIPURPINK1,
    launchDate: "16-01-2025",
    networkType: "5G",
    salesPrice: 22999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 24999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
    },
  },

  {
    id: "REALME24",
    name: "REALME 14 PRO 5G 8/256",
    brand: "REALME",
    prebook: false,
    series: "REALME14PRO",
    image: REALME14PROJAIPURPINK1,
    launchDate: "16-01-2025",
    networkType: "5G",
    salesPrice: 24999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 26999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "6000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 7300 Energy Octa core ",
    },
  },

  {
    id: "REALME25",
    name: "REALME 14 PRO PLUS 5G 8/128",
    brand: "REALME",
    prebook: false,
    series: "REALME14PROPLUS",
    image: REALME14PROPLUSBIKANERPRUPLE,
    launchDate: "16-01-2025",
    networkType: "5G",
    salesPrice: 27999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 29999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.83″ (17.35 cm) 120Hz OLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 7s Gen 3 Octa core ",
    },
  },
  {
    id: "REALME26",
    name: "REALME 14 PRO PLUS 5G 8/256",
    brand: "REALME",
    prebook: false,
    series: "REALME14PROPLUS",
    image: REALME14PROPLUSBIKANERPRUPLE,
    launchDate: "16-01-2025",
    networkType: "5G",
    salesPrice: 29999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 31999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.83″ (17.35 cm) 120Hz OLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "6000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 7s Gen 3 Octa core ",
    },
  },
  {
    id: "REALME27",
    name: "REALME 14 PRO PLUS 5G 12/256",
    brand: "REALME",
    prebook: false,
    series: "REALME14PROPLUS",
    image: REALME14PROPLUSBIKANERPRUPLE,
    launchDate: "16-01-2025",
    networkType: "5G",
    salesPrice: 30999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.5,
    newArrival: true,
    mrp: 34999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.83″ (17.35 cm) 120Hz OLED ",
      "Rear Camera": "50+8+50 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "6000 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 7s Gen 3 Octa core ",
    },
  },

  //SAMSUNG PHONES

  {
    id: "SAMSUNGA261",
    name: "SAMSUNG A26 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA26",
    image: SAMSUNGA26AWESOMEMINT1,
    launchDate: "24-03-2025",
    salesPrice: 24999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 27999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Samsung Exynos 1380 Octa core ",
    },
  },
  {
    id: "SAMSUNGA262",
    name: "SAMSUNG A26 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA26",
    image: SAMSUNGA26AWESOMEMINT1,
    launchDate: "24-03-2025",
    salesPrice: 27999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 30999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Samsung Exynos 1380 Octa core ",
    },
  },
  {
    id: "SAMSUNGA361",
    name: "SAMSUNG A36 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA36",
    image: SAMSUNGA36AWESOMELAVENDER1,
    launchDate: "02-03-2025",
    salesPrice: 30999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 35999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15",
      Processor: "Snapdragon 6 Gen 3 Octa core ",
    },
  },
  {
    id: "SAMSUNGA362",
    name: "SAMSUNG A36 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA36",
    image: SAMSUNGA36AWESOMELAVENDER1,
    launchDate: "02-03-2025",
    salesPrice: 33999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 38999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15",
      Processor: "Snapdragon 6 Gen 3 Octa core ",
    },
  },
  {
    id: "SAMSUNGA363",
    name: "SAMSUNG A36 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA36",
    image: SAMSUNGA36AWESOMELAVENDER1,
    launchDate: "02-03-2025",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 40999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15",
      Processor: "Snapdragon 6 Gen 3 Octa core ",
    },
  },

  {
    id: "SAMSUNGA561",
    name: "SAMSUNG A56 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA56",
    image: SAMSUNGA56AWESOMEOLIVE1,
    launchDate: "02-03-2025",
    salesPrice: 41999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 43999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Samsung Octa core ",
    },
  },
  {
    id: "SAMSUNGA562",
    name: "SAMSUNG A56 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA56",
    image: SAMSUNGA56AWESOMEOLIVE1,
    launchDate: "02-03-2025",
    salesPrice: 44999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 47999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Samsung Octa core ",
    },
  },
  {
    id: "SAMSUNGA563",
    name: "SAMSUNG A56 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGA56",
    image: SAMSUNGA56AWESOMEOLIVE1,
    launchDate: "02-03-2025",
    salesPrice: 47999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.1,
    newArrival: true,
    mrp: 50999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Samsung Octa core ",
    },
  },

  {
    id: "SAMSUNGA065G1",
    name: "SAMSUNG A06 5G 4/64",
    brand: "SAMSUNG",
    prebook: false,
    series: "A065G",
    image: SAMSUNGA065GLIGHTGREEN1,
    launchDate: "19-02-2025",
    salesPrice: 10499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 13499,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.7″ (17.02 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "SAMSUNGA065G2",
    name: "SAMSUNG A06 5G 4/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "A065G",
    image: SAMSUNGA065GLIGHTGREEN1,
    launchDate: "19-02-2025",
    salesPrice: 11499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 14499,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "SAMSUNGA065G3",
    name: "SAMSUNG A06 5G 6/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "A065G",
    image: SAMSUNGA065GLIGHTGREEN1,
    launchDate: "19-02-2025",
    salesPrice: 12499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 15499,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "SAMSUNG1",
    name: "SAMSUNG A16 5G 6/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "A165G",
    image: SAMSUNGA165GGOLD1,
    launchDate: "07-10-2024",
    salesPrice: 15499,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.7,
    newArrival: true,
    mrp: 19999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "SAMSUNG2",
    name: "SAMSUNG A16 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "A165G",
    image: SASMSUNGA165GBLUEBLACK1,
    launchDate: "07-10-2024",
    salesPrice: 16999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 21499,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "SAMSUNG3",
    name: "SAMSUNG A16 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "A165G",
    image: SAMSUNGA165GGOLD1,
    launchDate: "07-10-2024",
    salesPrice: 19999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.7,
    newArrival: true,
    mrp: 24499,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },
  {
    id: "SAMSUNG4",
    name: "SAMSUNG F15 5G 4/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "F155G",
    image: SAMSUNGF15JAZZYGREEN1,
    launchDate: "04-03-2024",
    networkType: "5G",
    salesPrice: 12499,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "SAMSUNG5",
    name: "SAMSUNG F15 5G 6/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "F155G",
    image: SAMSUNGF15ASHBLACK1,
    launchDate: "04-03-2024",
    networkType: "5G",
    salesPrice: 13499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 16999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },
  {
    id: "SAMSUNG6",
    name: "SAMSUNG F15 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "F155G",
    image: SAMSUNGF15GROOVYVIOLET1,
    launchDate: "04-03-2024",
    networkType: "5G",
    salesPrice: 14999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 17999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.5″ (16.51 cm) 90Hz Super AMOLED ",
      "Rear Camera": "50+5+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 6100 Plus Octa core ",
    },
  },

  {
    id: "SAMSUNG10",
    name: "SAMSUNG M14 4G 4/64",
    brand: "SAMSUNG",
    image: SAMSUNGM14SPPHIREBLUE1,
    launchDate: "07-03-2024",
    networkType: "4G",
    salesPrice: 8999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 17999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
      Processor: "Samsung Exynos 1330 Octa core ",
    },
  },

  {
    id: "SAMSUNG11",
    name: "SAMSUNG A14 5G 4/128",
    brand: "SAMSUNG",
    image: SAMSUNGA145GBLACK1,
    launchDate: "04-01-2023",
    networkType: "5G",
    salesPrice: 12999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4,
    newArrival: true,
    mrp: 15499,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
      Processor: "Samsung Exynos 1330 Octa core ",
    },
  },

  {
    id: "SAMSUNG13",
    name: "SAMSUNG F14 4G 4/64",
    brand: "SAMSUNG",
    image: SAMSUNGF14,
    launchDate: "02-08-2024",
    networkType: "5G",
    salesPrice: 8999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 11999,

    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.6″ (16.76 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "6000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v13 ",
      Processor: "Samsung Exynos 1330 Octa core ",
    },
  },

  {
    id: "SAMSUNG14",
    name: "SAMSUNG A05S 4G 4/128",
    brand: "SAMSUNG",
    image: A05SBLACK1,
    launchDate: "01-04-2023",
    networkType: "4G",
    salesPrice: 11499,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 90Hz PLS LCD ",
      "Rear Camera": "50+2+2 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v13 ",
      Processor: "Snapdragon 680 Octa core ",
    },
  },

  {
    id: "SAMSUNG15",
    name: "SAMSUNG A06 4G 4/64",
    brand: "SAMSUNG",
    image: SAMSUNGA06BLACK1,
    launchDate: "16-08-2024",
    networkType: "4G",
    salesPrice: 9999,
    specialOffer: false,
    trendingPhone: false,
    rating: 3.9,
    newArrival: true,
    mrp: 11499,
    ram: 4,
    storage: 64,
    specifcatin: {
      Display: "6.7″ (17.02 cm) 60Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
      Processor: "MediaTek Helio G85 Octa core ",
    },
  },

  {
    id: "SAMSUNG18",
    name: "SAMSUNG A35 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "A355G",
    image: SAMSUNGA35AWESOMELILAC1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 25999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.2,
    newArrival: true,
    mrp: 36999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "Samsung Exynos 1380 Octa core ",
    },
  },
  {
    id: "SAMSUNG17",
    name: "SAMSUNG A35 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "A355G",
    image: SAMSUNGA35AWESOMEICEBLUE1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 28999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 36999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+8+5 MP",
      "Front Camera": "13 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "Samsung Exynos 1380 Octa core ",
    },
  },

  {
    id: "SAMSUNG20",
    name: "SAMSUNG A55 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "A555G",
    image: SAMSUNGA55AWESOMENAVY1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 33999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 42999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 1480 Octa core ",
    },
  },

  {
    id: "SAMSUNG21",
    name: "SAMSUNG A55 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "A555G",
    image: SAMSUNGA55AWESOMENAVY1,
    launchDate: "01-04-2024",
    networkType: "5G",
    salesPrice: 36999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 45999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 1480 Octa core ",
    },
  },

  {
    id: "SAMSUNG19",
    name: "SAMSUNG A55 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "A555G",
    image: SAMSUNGA55BLUE1,
    launchDate: "11-03-2024",
    networkType: "5G",
    salesPrice: 39999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 48999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.6″ (16.76 cm) 120Hz Super AMOLED ",
      "Rear Camera": "50+12+5 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 1480 Octa core ",
    },
  },

  {
    id: "SAMSUNG24",
    name: "SAMSUNG S24 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 54999,
    specialOffer: false,
    trendingPhone: true,
    rating: 4.5,
    newArrival: true,
    mrp: 74999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 2400 Deca Core ",
    },
  },
  {
    id: "SAMSUNG25",
    name: "SAMSUNG S24 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 60999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 79999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 2400 Deca Core ",
    },
  },
  {
    id: "SAMSUNG26",
    name: "SAMSUNG S24 5G 8/512",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24",
    image: SAMSUNGS245GMARBLEGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 72999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 89999,
    ram: 8,
    storage: 512,
    specifications: {
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "8 GB | 512 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 2400 Deca Core ",
    },
  },
  {
    id: "SAMSUNG27",
    name: "SAMSUNG s24 plus 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24PLUS",
    image: SAMSUNGS24PLUS5GCOBALTVIOLET1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 64999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 99999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 2400 Deca Core ",
    },
  },
  {
    id: "SAMSUNG28",
    name: "SAMSUNG s24 plus 5G 12/512",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24PLUS",
    image: SAMSUNGS24PLUS5GCOBALTVIOLET1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 74999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 119999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 2400 Deca Core ",
    },
  },
  {
    id: "SAMSUNG29",
    name: "SAMSUNG S24 Ultra 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24ULTRA",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 109999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 134999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 8 Gen 3 Octa core ",
    },
  },
  {
    id: "SAMSUNG30",
    name: "SAMSUNG S24 Ultra 5G 12/512",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24ULTRA",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 119999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 144999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 8 Gen 3 Octa core ",
    },
  },
  {
    id: "SAMSUNG31",
    name: "SAMSUNG S24 Ultra 5G 12/ 1TB",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24ULTRA",
    image: SAMSUNGS24ULTRA5GTITANIUMGRAY1,
    launchDate: "17-01-2024",
    networkType: "5G",
    salesPrice: 139999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 144999,
    ram: 12,
    storage: 1000,
    specifications: {
      Display: "6.8″ (17.27 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+12+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 1TB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 8 Gen 3 Octa core ",
    },
  },
  {
    id: "SAMSUNG32",
    name: "SAMSUNG S24 FE 5G 8/128",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24FE",
    image: SAMSUNGS24FE5GGRAPHITE1,
    launchDate: "26-09-2024",
    networkType: "5G",
    salesPrice: 59999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 64999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+8 MP",
      "Front Camera": "10 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "4700 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 2400e Deca Core ",
    },
  },
  {
    id: "SAMSUNG33",
    name: "SAMSUNG S24 FE 5G 8/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "S24FE",
    image: SAMSUNGS24FE5GBLUE1,
    launchDate: "26-09-2024",
    networkType: "5G",
    salesPrice: 65999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 79999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+8 MP",
      "Front Camera": "10 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4700 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Samsung Exynos 2400e Deca Core ",
    },
  },

  {
    id: "SAMSUNG34",
    name: "SAMSUNG S25 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "S25",
    image: SAMSUNGS25ICYBLUE1,
    launchDate: "23-01-2025",
    networkType: "5G",
    salesPrice: 80999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 84999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 8 Elite Octa core ",
    },
  },

  {
    id: "SAMSUNG35",
    name: "SAMSUNG S25 5G 12/512",
    brand: "SAMSUNG",
    prebook: false,
    series: "S25",
    image: SAMSUNGS25ICYBLUE1,
    launchDate: "23-01-2025",
    networkType: "5G",
    salesPrice: 92999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 95999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.2″ (15.75 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "4000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 8 Elite Octa core ",
    },
  },
  {
    id: "SAMSUNG36",
    name: "SAMSUNG S25 PLUS 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "S25PLUS",
    image: SASMUNGS25PLUSNAVY1,
    launchDate: "23-01-2025",
    networkType: "5G",
    salesPrice: 99999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 103999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 8 Elite Octa core ",
    },
  },

  {
    id: "SAMSUNG37",
    name: "SAMSUNG S25 PLUS 5G 12/512",
    brand: "SAMSUNG",
    prebook: false,
    series: "S25PLUS",
    image: SASMUNGS25PLUSNAVY1,
    launchDate: "23-01-2025",
    networkType: "5G",
    salesPrice: 119999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 123999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "50+12+10 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "4900 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 8 Elite Octa core ",
    },
  },

  {
    id: "SAMSUNG38",
    name: "SAMSUNG S25 ULTRA 5G 12/256",
    brand: "SAMSUNG",
    prebook: false,
    series: "S25ULTRA",
    image: SAMSUNGS25ULTRATITANIUMBLACK1,
    launchDate: "23-01-2025",
    networkType: "5G",
    salesPrice: 129999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 133999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.9″ (17.53 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+50+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 8 Elite Octa core ",
    },
  },

  {
    id: "SAMSUNG39",
    name: "SAMSUNG S25 ULTRA 5G 12/512",
    brand: "SAMSUNG",
    prebook: false,
    series: "S25ULTRA",
    image: SAMSUNGS25ULTRATITANIUMBLACK1,
    launchDate: "23-01-2025",
    networkType: "5G",
    salesPrice: 141999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 144999,
    ram: 12,
    storage: 512,
    specifications: {
      Display: "6.9″ (17.53 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+50+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 512 GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 8 Elite Octa core ",
    },
  },
  {
    id: "SAMSUNG40",
    name: "SAMSUNG S25 ULTRA 5G 12/1TB",
    brand: "SAMSUNG",
    prebook: false,
    series: "S25ULTRA",
    image: SAMSUNGS25ULTRATITANIUMBLACK1,
    launchDate: "23-01-2025",
    networkType: "5G",
    salesPrice: 165999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 169999,
    ram: 12,
    storage: 1000,
    specifications: {
      Display: "6.9″ (17.53 cm) 120Hz Dynamic AMOLED 2x ",
      "Rear Camera": "200+50+10+50 MP",
      "Front Camera": "12 MP",
      "RAM | Storage": "12 GB | 1TB GB ",
      Battery: "5000 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v15 ",
      Processor: "Snapdragon 8 Elite Octa core ",
    },
  },

  {
    id: "SAMSUNG41",
    name: "SAMSUNG F05 4G 4/64",
    brand: "SAMSUNG",
    prebook: false,
    series: "SAMSUNGF05",
    image: SAMSUNGF05TWILIGHTBLUE1,
    launchDate: "20-09-2024",
    networkType: "4G",
    salesPrice: 7999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 9999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.7″ (17.02 cm) 60Hz PLS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5000 mAh | 25W Fast Charging ",
      Network: "Dual SIM: 4G & 4G",
      OS: "Android v14 ",
      Processor: "MediaTek Helio G85 Octa core ",
    },
  },

  //IQoo

  {
    id: "IQOO1",
    name: "IQOO NEO 9 PRO 5G 8/256",
    brand: "IQOO",
    prebook: false,
    series: "NEO9PRO",
    image: IQOONEO9PROFIERYRED1,
    launchDate: "22-02-2024",
    networkType: "5G",
    salesPrice: 35899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 41999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.78″ (17.22 cm) 144Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5160 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 8 Gen 2 Octa core ",
    },
  },

  {
    id: "IQOO2",
    name: "IQOO NEO 9 PRO 5G 12/256",
    brand: "IQOO",
    prebook: false,
    series: "NEO9PRO",
    image: IQOONEO9PROFIERYRED1,
    launchDate: "22-02-2024",
    networkType: "5G",
    salesPrice: 37899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 44999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.78″ (17.22 cm) 144Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5160 mAh | 120W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 8 Gen 2 Octa core ",
    },
  },

  {
    id: "IQOO3",
    name: "IQOO Z9 5G 8/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9",
    image: IQOOZ9GRAPHENEBLUE1,
    launchDate: "12-03-2024",
    networkType: "5G",
    salesPrice: 18399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 24999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7200 Octa core ",
    },
  },

  {
    id: "IQOO4",
    name: "IQOO Z9 5G 8/256",
    brand: "IQOO",
    prebook: false,
    series: "Z9",
    image: IQOOZ9GRAPHENEBLUE1,
    launchDate: "12-03-2024",
    networkType: "5G",
    salesPrice: 20399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: true,
    mrp: 26999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7200 Octa core ",
    },
  },

  {
    id: "IQOO5",
    name: "IQOO Z9 LITE 5G 4/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9LITE",
    image: IQOOZ9LITEMOCHABROWN1,
    launchDate: "15-07-2024",
    networkType: "5G",
    salesPrice: 10399,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 14499,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "IQOO6",
    name: "IQOO Z9 LITE 5G 6/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9LITE",
    image: IQOOZ9LITEMOCHABROWN1,
    launchDate: "15-07-2024",
    networkType: "5G",
    salesPrice: 11399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.2,
    newArrival: true,
    mrp: 15499,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.56″ (16.66 cm) 90Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5000 mAh | 15W Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14",
      Processor: "MediaTek Dimensity 6300 Octa core ",
    },
  },

  {
    id: "IQOO7",
    name: "IQOO Z9S 5G 8/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9S",
    image: IQOOZ9SONYXGREEN1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 19899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 25999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Octa core ",
    },
  },

  {
    id: "IQOO8",
    name: "IQOO Z9S 5G 8/256 ",
    brand: "IQOO",
    prebook: false,
    series: "Z9S",
    image: IQOOZ9SONYXGREEN1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 21899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 27999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Octa core ",
    },
  },

  {
    id: "IQOO9",
    name: "IQOO Z9S 5G 12/256 ",
    brand: "IQOO",
    prebook: false,
    series: "Z9S",
    image: IQOOZ9SONYXGREEN1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 23899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 29999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5500 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Octa core ",
    },
  },

  {
    id: "IQOO10",
    name: "IQOO Z9X 5G 4/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9x",
    image: IQOOZ9XSTORMGREY1,
    launchDate: "16-05-2024",
    networkType: "5G",
    salesPrice: 12399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 17999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 6 Gen 1 Octa core ",
    },
  },

  {
    id: "IQOO11",
    name: "IQOO Z9X 5G 6/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9x",
    image: IQOOZ9XSTORMGREY1,
    launchDate: "16-05-2024",
    networkType: "5G",
    salesPrice: 13899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 18999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 6 Gen 1 Octa core ",
    },
  },

  {
    id: "IQOO12",
    name: "IQOO Z9X 5G 8/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9x",
    image: IQOOZ9XSTORMGREY1,
    launchDate: "16-05-2024",
    networkType: "5G",
    salesPrice: 15399,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.1,
    newArrival: true,
    mrp: 19999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.72″ (17.07 cm) 120Hz LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "8 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "6000 mAh | 44W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 6 Gen 1 Octa core ",
    },
  },

  {
    id: "IQOO13",
    name: "IQOO Z9S PRO 5G 8/128",
    brand: "IQOO",
    prebook: false,
    series: "Z9SPRO",
    image: IQOOZ9SPROLUXEMARBLE3,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 24899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 29999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },

  {
    id: "IQOO14",
    name: "IQOO Z9S PRO 5G 8/256",
    brand: "IQOO",
    prebook: false,
    series: "Z9SPRO",
    image: IQOOZ9SPROLUXEMARBLE3,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 26899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 31999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },

  {
    id: "IQOO15",
    name: "IQOO Z9S PRO 5G 12/256",
    brand: "IQOO",
    prebook: false,
    series: "Z9SPRO",
    image: IQOOZ9SPROLUXEMARBLE3,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 28899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: true,
    mrp: 33999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.77″ (17.2 cm) 120Hz AMOLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5500 mAh | 80W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },

  //MOTOROLA

  {
    id: "MOTOROLA1",
    name: "MOTOROLA G45 5G 4/128",
    brand: "MOTOROLA",
    prebook: false,
    series: "G45",
    image: MOTOROLAG45VIVAMAGENTA1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 10899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 12999,
    ram: 4,
    storage: 128,
    specifications: {
      Display: "6.5″ (16.51 cm) 120Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "4 GB | 128 GB ",
      Battery: "5000 mAh | 20W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 6s Gen 3 Octa core ",
    },
  },

  {
    id: "MOTOROLA2",
    name: "MOTOROLA G45 5G 8/128",
    brand: "MOTOROLA",
    prebook: false,
    series: "G45",
    image: MOTOROLAG45VIVAMAGENTA1,
    launchDate: "21-08-2024",
    networkType: "5G",
    salesPrice: 12899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 14999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.5″ (16.51 cm) 120Hz IPS LCD ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 20W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 6s Gen 3 Octa core ",
    },
  },

  {
    id: "MOTOROLA3",
    name: "MOTOROLA G85 5G 8/128",
    brand: "MOTOROLA",
    prebook: false,
    series: "G85",
    image: MOTOROLAG85COBALTBLUE1,
    launchDate: "10-07-2024",
    networkType: "5G",
    salesPrice: 17999,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 20999,
    ram: 8,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz P-OLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 128 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 6s Gen 3 Octa core ",
    },
  },

  {
    id: "MOTOROLA4",
    name: "MOTOROLA G85 5G 12/256",
    brand: "MOTOROLA",
    prebook: false,
    series: "G85",
    image: MOTOROLAG85COBALTBLUE1,
    launchDate: "10-07-2024",
    networkType: "5G",
    salesPrice: 19899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 22999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz P-OLED ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 6s Gen 3 Octa core ",
    },
  },

  {
    id: "MOTOROLA5",
    name: "MOTOROLA G64 5G 12/256",
    brand: "MOTOROLA",
    prebook: false,
    series: "G64",
    image: MOTOROLAG64ICELILAC1,
    launchDate: "16-04-2024",
    networkType: "5G",
    salesPrice: 16899,
    specialOffer: true,
    trendingPhone: false,
    rating: 4.2,
    newArrival: false,
    mrp: 19999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.5″ (16.51 cm) 120Hz IPS LCD ",
      "Rear Camera": "50+8 MP",
      "Front Camera": "16 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "6000 mAh | 33W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7025 Octa core ",
    },
  },

  {
    id: "MOTOROLA6",
    name: "MOTOROLA EDGE 50 NEO 5G 8/256",
    brand: "MOTOROLA",
    prebook: false,
    series: "EDGE50NEO",
    image: MOTOROLAEDGE50NEOPANTONELATTE1,
    launchDate: "16-09-2024",
    networkType: "5G",
    salesPrice: 20899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 29999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.4″ (16.26 cm) 120Hz P-OLED ",
      "Rear Camera": "50+13+10 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB | 256 GB ",
      Battery: "4310 mAh | 68W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7300 Octa core ",
    },
  },

  {
    id: "MOTOROLA7",
    name: "MOTOROLA EDGE 50 FUSION 5G 12/256",
    brand: "MOTOROLA",
    prebook: false,
    series: "EDGE50FUSION",
    image: MOTOROLAEDGE50FUSIONFORESTGREEN1,
    launchDate: "16-04-2024",
    networkType: "5G",
    salesPrice: 24899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.5,
    newArrival: false,
    mrp: 27999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 144Hz P-OLED",
      "Rear Camera": "50+13 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "5000 mAh | 68W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14  ",
      Processor: "Snapdragon 7s Gen 2 Octa core ",
    },
  },

  {
    id: "MOTOROLA8",
    name: "MOTOROLA EDGE 50 5G  8/256",
    brand: "MOTOROLA",
    prebook: false,
    series: "EDGE50",
    image: MOTOROLAEDGE50PEACHFUZZ1,
    launchDate: "01-08-2024",
    networkType: "5G",
    salesPrice: 25899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: false,
    mrp: 32999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 120Hz P-OLED",
      "Rear Camera": "50+13+10 MP",
      "Front Camera": "32 MP",
      "RAM | Storage": "8 GB RAM | 256 GB ",
      Battery: "5000 mAh | 68W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14  ",
      Processor: "Snapdragon 7 Gen 1 Accelerated Edition",
    },
  },

  {
    id: "MOTOROLA9",
    name: "MOTOROLA EDGE 50 PRO 5G 12/256",
    brand: "MOTOROLA",
    prebook: false,
    series: "EDGE50PRO",
    image: MOTOROLAEDGE50PROCANEELBAY1,
    launchDate: "03-04-2024",
    networkType: "5G",
    salesPrice: 31899,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.4,
    newArrival: false,
    mrp: 41999,
    ram: 12,
    storage: 256,
    specifications: {
      Display: "6.7″ (17.02 cm) 144Hz P-OLED ",
      "Rear Camera": "50+13+10 MP",
      "Front Camera": "50 MP",
      "RAM | Storage": "12 GB | 256 GB ",
      Battery: "4500 mAh | 125W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 7 Gen 3 Octa core ",
    },
  },

  //POCO

  {
    id: "POCO1",
    name: "POCO C75 5G 4/64",
    brand: "POCO",
    prebook: false,
    series: "C75",
    image: POCOC75SILVERSTARDUST1,
    launchDate: "19-12-2024",
    networkType: "5G",
    salesPrice: 7999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.0,
    newArrival: true,
    mrp: 10999,
    ram: 4,
    storage: 64,
    specifications: {
      Display: "6.88″ (17.48 cm) 120Hz IPS LCD ",
      "Rear Camera": "50 MP",
      "Front Camera": "5 MP",
      "RAM | Storage": "4 GB | 64 GB ",
      Battery: "5160 mAh | 18W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "Snapdragon 4s Gen 2 Octa core ",
    },
  },

  {
    id: "POCO2",
    name: "POCO M7 PRO 5G 6/128",
    brand: "POCO",
    prebook: false,
    series: "M7PRO",
    image: POCOM7PROLAVENDERFROST1,
    launchDate: "20-12-2024",
    networkType: "5G",
    salesPrice: 13999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 18999,
    ram: 6,
    storage: 128,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz G-OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "6 GB | 128 GB ",
      Battery: "5110 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7025 Ultra Octa core ",
    },
  },

  {
    id: "POCO3",
    name: "POCO M7 PRO 5G 8/256",
    brand: "POCO",
    prebook: false,
    series: "M7PRO",
    image: POCOM7PROLAVENDERFROST1,
    launchDate: "20-12-2024",
    networkType: "5G",
    salesPrice: 16999,
    specialOffer: false,
    trendingPhone: false,
    rating: 4.3,
    newArrival: true,
    mrp: 20999,
    ram: 8,
    storage: 256,
    specifications: {
      Display: "6.67″ (16.94 cm) 120Hz G-OLED ",
      "Rear Camera": "50+2 MP",
      "Front Camera": "20 MP",
      "RAM | Storage": "8 GB | 256  GB ",
      Battery: "5110 mAh | 45W Fast Charging ",
      Network: "Dual SIM: 5G & 5G",
      OS: "Android v14 ",
      Processor: "MediaTek Dimensity 7025 Ultra Octa core ",
    },
  },
];
